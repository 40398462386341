import { orderPartsUrl } from '@trinity/utils'

export function composePartsUrl(dealer: TrinityAPI.DealerType | null) {
  const firstName = dealer?.firstName ?? ''
  const lastName = dealer?.lastName ?? ''
  const companyName = dealer?.companyName ?? ''
  const email = dealer?.email ?? ''

  return `${orderPartsUrl}?yourName[first]=${firstName}&yourName[last]=${lastName}&companyName=${companyName}&emailAddress=${email}`
}
