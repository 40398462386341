import { type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'

export const clientRoutes: RouteObject = {
  path: 'clients',
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      async lazy() {
        const { Clients, loader } = await import('./clients')
        return { Component: Clients, loader }
      },
    },
    {
      path: ':id',
      children: [
        {
          index: true,
          async lazy() {
            const { Client, loader } = await import('./clients.$id')
            return { Component: Client, loader }
          },
        },
        {
          path: 'edit',
          async lazy() {
            const { EditClient, loader, action } = await import('./clients.$id.edit')
            return { Component: EditClient, loader, action }
          },
        },
        {
          path: 'closet',
          children: [
            {
              index: true,
              async lazy() {
                const { Closet, loader, action } = await import('./clients.$id.closet')
                return { Component: Closet, loader, action }
              },
            },
            {
              path: 'archived',
              async lazy() {
                const { Archived, loader, action } = await import('./clients.$id.closet.archived')
                return { Component: Archived, loader, action }
              },
            },
          ],
        },
      ],
    },
    {
      path: 'new',
      async lazy() {
        const { NewClient, action } = await import('./clients.new')
        return { Component: NewClient, action }
      },
    },
    {
      path: 'insights',
      async lazy() {
        const { Insights, loader } = await import('./clients.insights')
        return { Component: Insights, loader }
      },
    },
  ],
}
