import * as Sentry from '@sentry/react'
import { IntercomProvider } from 'react-use-intercom'

const INTERCOM_ID = 'kasssq3w'

interface AppProviderProps {
  children: React.ReactNode
}

function AppProviders({ children }: AppProviderProps) {
  return (
    <Sentry.ErrorBoundary fallback={({ error, resetError }) => <ErrorFallback error={error} resetError={resetError} />}>
      <IntercomProvider appId={INTERCOM_ID}>{children}</IntercomProvider>
    </Sentry.ErrorBoundary>
  )
}

export default AppProviders

interface ErrorFallbackProps {
  error: unknown
  resetError: () => void
}

function ErrorFallback({ error, resetError }: ErrorFallbackProps) {
  return (
    <div style={{ padding: '10rem' }}>
      <h1>You have encountered an error:</h1>
      <h5>{error?.toString()}</h5>
      <button onClick={resetError}>Try Again</button>
    </div>
  )
}
