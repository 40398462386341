import { type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'

export const newOrderRoutes: RouteObject = {
  path: 'new-order',
  async lazy() {
    const { NewOrder, loader, action } = await import('./new-order')
    return { Component: NewOrder, loader, action }
  },
  errorElement: <ErrorBoundary />,
}
