import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, ClickAwayListener, Grid } from '@mui/material'
import { useGlobalState } from '@trinity/components'
import { navLinks } from '../shared'
import Menu from './Menu'

function Nav() {
  const { onTablet } = useGlobalState()
  const [menuItem, setMenuItem] = useState<HTMLElement | null>(null)

  return (
    <>
      <Grid item sx={{ flex: '1 0 auto' }}>
        <ClickAwayListener onClickAway={() => setMenuItem(null)}>
          <Grid
            container
            justifyContent='space-evenly'
            wrap='nowrap'
            alignItems='center'
            spacing={4}
            sx={{ maxWidth: '100%' }}
          >
            {navLinks.map(navLink => (
              <Menu
                key={navLink.title}
                navLink={navLink}
                menuItem={menuItem}
                setMenuItem={(item: typeof menuItem) => setMenuItem(item)}
              />
            ))}
          </Grid>
        </ClickAwayListener>
      </Grid>
      {!onTablet && (
        <Grid item>
          <Button
            component={Link}
            size='small'
            variant='outlined'
            color='success'
            to='new-order'
            sx={{
              typography: 'nav1',
              color: 'success',
              whiteSpace: 'nowrap',
            }}
          >
            + NEW ORDER
          </Button>
        </Grid>
      )}
    </>
  )
}

export default Nav
