import { Navigate, type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'
import { PrivateRoute } from 'routes'

export const paymentRoutes: RouteObject = {
  path: 'payments',
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      element: <Navigate replace to='credit-cards' />,
    },
    {
      path: 'credit-cards',
      children: [
        {
          index: true,
          async lazy() {
            const { CreditCards, loader, action } = await import('./credit-cards')
            return {
              element: (
                <PrivateRoute permission='can_manage_credit_cards'>
                  <CreditCards />
                </PrivateRoute>
              ),
              loader,
              action,
            }
          },
        },
        {
          path: 'new',
          async lazy() {
            const { NewCreditCard, loader, action } = await import('./credit-cards.new')
            return {
              element: (
                <PrivateRoute permission='can_manage_credit_cards'>
                  <NewCreditCard />
                </PrivateRoute>
              ),
              loader,
              action,
            }
          },
        },
        {
          path: ':token',
          async lazy() {
            const { EditCreditCard, loader, action } = await import('./credit-cards.$token')
            return {
              element: (
                <PrivateRoute permission='can_manage_credit_cards'>
                  <EditCreditCard />
                </PrivateRoute>
              ),
              loader,
              action,
            }
          },
        },
      ],
    },
    {
      path: 'manage',
      async lazy() {
        const { Manage, loader, action } = await import('./manage')
        return {
          element: (
            <PrivateRoute permission='can_view_balances'>
              <Manage />
            </PrivateRoute>
          ),
          loader,
          action,
        }
      },
    },
  ],
}
