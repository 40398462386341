import { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import {
  ClickAwayListener,
  Divider,
  Grid,
  Link,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  styled,
  Typography,
} from '@mui/material'
import { useGlobalState } from '@trinity/components'
import type { NavList } from '../shared'
import { composePartsUrl } from '../shared'

interface MenuProps {
  navLink: NavList
  menuItem: HTMLElement | null
  setMenuItem: (menuItem: HTMLElement | null) => void
}

function Menu({ navLink, menuItem, setMenuItem }: MenuProps) {
  const { onTablet, user } = useGlobalState()
  const [arrowRef, setArrowRef] = useState<null | HTMLElement>(null)
  const { showNewMessage } = useIntercom()

  if (navLink.permissions && !user?.permissions.nav.includes(navLink.permissions)) {
    return null
  }

  if (!navLink?.menu) {
    return (
      <Grid item>
        <Link
          variant='nav1'
          underline='none'
          color='grey.500'
          href={navLink?.link}
          sx={{
            textTransform: 'uppercase',
            '&:hover': { color: 'grey.700' },
          }}
        >
          {navLink?.title}
        </Link>
      </Grid>
    )
  }

  return (
    <Grid item>
      <Typography
        variant='nav1'
        component={Link}
        href='#'
        onMouseEnter={(event: React.SyntheticEvent<HTMLElement>) => setMenuItem(event.currentTarget)}
        onFocus={(event: React.SyntheticEvent<HTMLElement>) => setMenuItem(event.currentTarget)}
        sx={{
          cursor: 'pointer',
          textTransform: 'uppercase',
          textDecoration: 'none',
          '&:hover': { color: 'grey.700' },
        }}
      >
        {navLink.title}
      </Typography>
      <Popper
        disablePortal
        open={Boolean(menuItem?.innerHTML === navLink.title)}
        anchorEl={menuItem}
        sx={{ zIndex: 'tooltip' }}
        modifiers={[
          { name: 'arrow', enabled: true, options: { element: arrowRef } },
          { name: 'offset', enabled: true, options: { offset: [0, 36] } },
        ]}
      >
        <Arrow ref={setArrowRef} />
        <Paper
          sx={{
            px: 2,
            py: 1,
            border: 'n100',
            '& > ul > li': {
              '&:hover': {
                backgroundColor: 'unset',
              },
            },
          }}
        >
          <ClickAwayListener onClickAway={() => (onTablet ? null : setMenuItem(null))}>
            <MenuList id='nav' onMouseLeave={() => (onTablet ? null : setMenuItem(null))}>
              {navLink.menu.map(item => {
                if (item.divider) {
                  return (
                    <MenuItem key={item.title}>
                      <Divider sx={{ borderWidth: 1, width: 1, cursor: 'default' }} />
                    </MenuItem>
                  )
                } else if (item.permissions && user?.permissions.nav.includes(item.permissions)) {
                  return (
                    <MenuItem key={item.title} onClick={() => (item.title === 'Contact Us' ? showNewMessage() : null)}>
                      <Link
                        component={item.reactPage ? RouterLink : Link}
                        variant='nav1'
                        underline='none'
                        color='grey.500'
                        to={item.link}
                        href={item.title === 'Order Parts' ? composePartsUrl(user?.dealer) : item.link}
                        sx={{
                          textTransform: 'uppercase',
                          '&:hover': { color: 'grey.700' },
                        }}
                      >
                        {item?.title}
                      </Link>
                    </MenuItem>
                  )
                } else {
                  return null
                }
              })}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </Grid>
  )
}

export default Menu

const Arrow = styled('div')(({ theme }) => ({
  '&::after, &::before': {
    bottom: -1,
    border: 'solid transparent',
    content: '""',
    position: 'absolute',
    pointerEvents: 'none',
  },
  '&::after': {
    borderBottomColor: theme.palette.common.white,
    borderWidth: 20,
    marginLeft: -20,
  },
  '&::before': {
    borderBottomColor: theme.palette.grey[100],
    borderWidth: 22,
    marginLeft: -22,
  },
}))
