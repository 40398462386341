import { useCallback, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useIntercom } from 'react-use-intercom'
import { Box, Dialog, Divider, IconButton, Link, Stack, Typography } from '@mui/material'
import { ArrowBackIos } from '@mui/icons-material'
import { useGlobalState } from '@trinity/components'
import type { NavList } from '../shared'
import { composePartsUrl } from '../shared'
import { AnimatedMenu } from './Animated'

interface MenuProps {
  navLink: NavList
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function Menu({ navLink, setIsOpen }: MenuProps) {
  const { user } = useGlobalState()
  const { boot, showNewMessage } = useIntercom()
  const [menuTitle, setMenuTitle] = useState('')

  const closeNav = useCallback(
    (title: string) => {
      if (title === 'Contact Us') {
        boot({
          email: user?.dealer?.email ?? '',
          userId: String(user?.id),
          name: user?.dealer?.name,
          userHash: user?.userHash ?? '',
        })
        showNewMessage()
      }
      setIsOpen(false)
    },
    [boot, setIsOpen, showNewMessage, user],
  )

  if (navLink.permissions && !user?.permissions.nav.includes(navLink.permissions)) {
    return null
  }

  if (!navLink.menu) {
    return (
      <Link
        variant='nav2'
        underline='none'
        color='grey.500'
        href={navLink?.link}
        sx={{
          textTransform: 'uppercase',
          '&:active, &:hover': { color: 'grey.700' },
        }}
      >
        {navLink?.title}
      </Link>
    )
  }

  return (
    <>
      <Typography
        key={navLink.title}
        variant='nav2'
        onClick={() => setMenuTitle(navLink.title ?? '')}
        sx={{
          textTransform: 'uppercase',
          '&:active, &:hover': {
            color: 'grey.700',
          },
        }}
      >
        {navLink.title}
      </Typography>
      <Dialog open={menuTitle === navLink.title} fullScreen sx={{ zIndex: 1299 }}>
        <IconButton
          size='large'
          onClick={() => setMenuTitle('')}
          sx={{
            justifyContent: 'flex-start',
            pt: 2,
            pl: 1.5,
          }}
        >
          <ArrowBackIos fontSize='large' />
          <Typography variant='nav2' sx={{ textTransform: 'uppercase', fontSize: '1.125rem' }}>
            {menuTitle}
          </Typography>
        </IconButton>
        <Stack justifyContent='center' alignItems='center' spacing={6} sx={{ py: 8 }}>
          {navLink.menu.map((item, index) => {
            if (item.divider) {
              return (
                <Box key={item.title} sx={{ width: 4 / 5 }}>
                  <AnimatedMenu index={index}>
                    <Divider flexItem sx={{ borderWidth: 1 }} />
                  </AnimatedMenu>
                </Box>
              )
            } else if (item.permissions && user?.permissions.nav.includes(item.permissions)) {
              return (
                <AnimatedMenu key={item.title} index={index}>
                  <Link
                    component={item.reactPage ? RouterLink : Link}
                    variant='nav2'
                    underline='none'
                    color='grey.500'
                    to={item.link}
                    href={item.title === 'Order Parts' ? composePartsUrl(user?.dealer) : item.link}
                    onClick={() => closeNav(item.title)}
                    sx={{
                      textTransform: 'uppercase',
                      '&:hover': { color: 'grey.700' },
                    }}
                  >
                    {item?.title}
                  </Link>
                </AnimatedMenu>
              )
            } else {
              return null
            }
          })}
        </Stack>
      </Dialog>
    </>
  )
}

export default Menu
