import { Navigate, type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'

export const libraryRoutes: RouteObject = {
  path: 'library',
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      element: <Navigate replace to='options' />,
    },
    {
      path: 'options',
      async lazy() {
        const { Options, action } = await import('./options')
        return { Component: Options, action }
      },
      children: [
        {
          index: true,
          async lazy() {
            const { OptionsIndex } = await import('./options.index')
            return { Component: OptionsIndex }
          },
        },
        {
          path: ':garmentType',
          children: [
            {
              index: true,
              async lazy() {
                const { OptionGroups, loader } = await import('./options.$garmentType')
                return { Component: OptionGroups, loader }
              },
            },
            {
              path: ':optionGroupId',
              children: [
                {
                  index: true,
                  async lazy() {
                    const { OptionsList, loader } = await import('./options.$garmentType.$optionGroupId')
                    return { Component: OptionsList, loader }
                  },
                },
                {
                  path: ':optionId',
                  async lazy() {
                    const { OptionValuesList, loader } = await import('./options.$garmentType.$optionGroupId.$optionId')
                    return { Component: OptionValuesList, loader }
                  },
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: 'models',
      async lazy() {
        const { Models } = await import('./models')
        return { Component: Models }
      },
      children: [
        {
          index: true,
          async lazy() {
            const { ModelsIndex } = await import('./models.index')
            return { Component: ModelsIndex }
          },
        },
        {
          path: ':garmentType',
          async lazy() {
            const { ModelsList, loader } = await import('./models.$garmentType')
            return { Component: ModelsList, loader }
          },
        },
      ],
    },
    {
      path: 'materials',
      async lazy() {
        const { Materials } = await import('./materials')
        return { Component: Materials }
      },
      children: [
        {
          index: true,
          async lazy() {
            const { MaterialsIndex } = await import('./materials.index')
            return { Component: MaterialsIndex }
          },
        },
        {
          path: 'buttons',
          async lazy() {
            const { Buttons, loader } = await import('./materials.buttons')
            return { Component: Buttons, loader }
          },
        },
        {
          path: 'felts',
          async lazy() {
            const { Felts, loader } = await import('./materials.felts')
            return { Component: Felts, loader }
          },
        },
        {
          path: 'microsuedes',
          async lazy() {
            const { Suedes, loader } = await import('./materials.suedes')
            return { Component: Suedes, loader }
          },
        },
        {
          path: 'pipings',
          async lazy() {
            const { Pipings, loader } = await import('./materials.pipings')
            return { Component: Pipings, loader }
          },
        },
        {
          path: 'name-labels',
          async lazy() {
            const { Labels, loader } = await import('./materials.labels')
            return { Component: Labels, loader }
          },
        },
        {
          path: 'threads',
          async lazy() {
            const { Threads, loader } = await import('./materials.threads')
            return { Component: Threads, loader }
          },
        },
        {
          path: 'trouser-trims',
          async lazy() {
            const { TrouserTrims, loader } = await import('./materials.trouserTrims')
            return { Component: TrouserTrims, loader }
          },
        },
        {
          path: 'zippers',
          async lazy() {
            const { Zippers, loader } = await import('./materials.zippers')
            return { Component: Zippers, loader }
          },
        },
      ],
    },
  ],
}
