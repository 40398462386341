import { Navigate, type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'

export const reportsRoutes: RouteObject = {
  path: 'reports',
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      element: <Navigate replace to='rank' />,
    },
    {
      path: 'rank',
      async lazy() {
        const { Rank, loader } = await import('./rank')
        return { Component: Rank, loader }
      },
    },
  ],
}
