import { Navigate, type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'

export const fabricsRoutes: RouteObject = {
  path: 'fabric-explorer',
  errorElement: <ErrorBoundary />,
  children: [
    { index: true, element: <Navigate replace to='collections' /> },
    {
      path: 'collections',
      children: [
        {
          index: true,
          async lazy() {
            const { Collections, loader, action } = await import('./collections')
            return { Component: Collections, loader, action }
          },
        },
        {
          path: ':collectionId',
          id: 'collection',
          async lazy() {
            const { Collection, loader } = await import('./collections.$id')
            return { Component: Collection, loader }
          },
          children: [
            {
              index: true,
              async lazy() {
                const { CollectionDefault, action } = await import('./collections.$id.index')
                return { Component: CollectionDefault, action }
              },
            },
            {
              path: 'status',
              async lazy() {
                const { CollectionStatus } = await import('./collections.$id.status')
                return { Component: CollectionStatus }
              },
            },
          ],
        },
      ],
    },
    {
      path: 'fabrics',
      children: [
        {
          index: true,
          async lazy() {
            const { Fabrics, loader, action } = await import('./fabrics')
            return { Component: Fabrics, loader, action }
          },
        },
        {
          path: ':fabricId',
          async lazy() {
            const { Fabric, loader, action } = await import('./fabrics.$id')
            return { Component: Fabric, loader, action }
          },
        },
      ],
    },
  ],
}
