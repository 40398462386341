import { useEffect } from 'react'
import { Link } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useIntercom } from 'react-use-intercom'
import { AppBar, Grid, Toolbar } from '@mui/material'
import { useGlobalState } from '@trinity/components'
import MobileNav from './MobileNav'
import Nav from './Nav'
import { DealerLogo } from './shared'

export function NavBar() {
  const { user, onMobile, onTablet } = useGlobalState()
  const { boot, shutdown } = useIntercom()
  const imageSize = onMobile ? 250 : onTablet ? 195 : 275

  useEffect(() => {
    // eslint-disable-next-line import/namespace
    Sentry.setTag('login_id', user?.id)
  }, [user])

  useEffect(() => {
    boot({
      email: user?.dealer?.email ?? '',
      userId: String(user?.id),
      name: user?.dealer?.name,
      userHash: user?.userHash ?? '',
    })

    if (onMobile) {
      shutdown()
    }
  }, [boot, onMobile, shutdown, user])

  return (
    <AppBar
      position='static'
      sx={{ bgcolor: 'common.white', boxShadow: theme => theme.elevation.divider, transform: 'none' }}
    >
      <Toolbar sx={{ height: { xs: 80, sm: 90 } }}>
        <Grid container justifyContent='space-between' alignItems='center' wrap='nowrap'>
          <Grid item sx={{ flex: { sm: 2 }, pl: { sm: 3 } }}>
            <Link to='dealer-home'>
              <DealerLogo url={user?.dealer?.logo} position='left' width={imageSize} />
            </Link>
          </Grid>
          {onMobile ? <MobileNav /> : <Nav />}
        </Grid>
      </Toolbar>
    </AppBar>
  )
}
