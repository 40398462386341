import { useState } from 'react'
import { Box, Dialog, Grid, Stack } from '@mui/material'
import { navLinks } from '../shared'
import Menu from './Menu'
import { AnimatedIcon, AnimatedMenu } from './Animated'

function MobileNav() {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <Grid item>
      <Box onClick={() => setIsOpen(isOpen => !isOpen)} sx={{ padding: 3 }}>
        <AnimatedIcon open={isOpen} side='top' top={35} />
        <AnimatedIcon open={isOpen} side='bottom' top={45} />
      </Box>
      <Dialog fullScreen open={isOpen} sx={{ zIndex: 1299 }}>
        <Stack justifyContent='center' alignItems='center' spacing={6} sx={{ height: 1 }}>
          {navLinks.map((navLink, index) => (
            <AnimatedMenu key={navLink.title} index={index}>
              <Menu navLink={navLink} setIsOpen={setIsOpen} />
            </AnimatedMenu>
          ))}
        </Stack>
      </Dialog>
    </Grid>
  )
}

export default MobileNav
