import { Navigate, type RouteObject } from 'react-router-dom'
import { ErrorBoundary } from '@trinity/components'
import { PrivateRoute } from 'routes'

export const priceListRoutes: RouteObject = {
  path: 'price-list',
  errorElement: <ErrorBoundary />,
  children: [
    {
      index: true,
      element: (
        <PrivateRoute permission='can_view_price_list'>
          <Navigate replace to='fabrics' />
        </PrivateRoute>
      ),
    },
    {
      path: 'fabrics',
      async lazy() {
        const { FabricPriceList, loader } = await import('./fabrics')
        return {
          element: (
            <PrivateRoute permission='can_view_price_list'>
              <FabricPriceList />
            </PrivateRoute>
          ),
          loader,
        }
      },
    },
    {
      path: 'options',
      async lazy() {
        const { OptionPriceList } = await import('./options')
        return {
          element: (
            <PrivateRoute permission='can_view_price_list'>
              <OptionPriceList />
            </PrivateRoute>
          ),
        }
      },
    },
  ],
}
