import { Box } from '@mui/material'

interface DealerLogoProps {
  width: number
  url?: string | null
  position: string
}

function DealerLogo({ width, url = '', position }: DealerLogoProps) {
  return (
    <Box
      sx={{
        height: 75,
        width: width,
        backgroundImage: `url(${url})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        backgroundPosition: position,
      }}
    />
  )
}

export default DealerLogo
