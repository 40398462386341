import React from 'react'
import { createRoot } from 'react-dom/client'
import { initSentry } from '@trinity/utils'
import { AppProviders } from 'components'
import App from './App'

const sentryDsn = 'https://b34f146efe3c8085a035958806a6a15f@o170893.ingest.us.sentry.io/4507108043915264'
const sentryRelease = `dealer@${__APP_VERSION__}`
initSentry(sentryDsn, sentryRelease)

const rootEl = document.getElementById('root')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(rootEl!)

root.render(
  <React.StrictMode>
    <AppProviders>
      <App />
    </AppProviders>
  </React.StrictMode>,
)
